<template>
  <div class="m-course">
    <div class="item" v-for="(item, index) of list" :key="index" @click="itemClick(item)">
      <img src="@/assets/images/courseGroup/freetag.png" alt="赠送" class="free-tag">
      <div class="title">{{item.courseGroupName}}</div>
      <div class="tip">{{item.subtitle}}</div>
      <div class="tip tip-space">{{item.classTime}}</div>
      <div class="teacher-list">
        <div class="t-itam" v-for="(titem, tindex) of item.teachers" :key="tindex">
          <img :src="titem.headPortrait" :alt="titem.teacherName">
          <p>{{titem.teacherName}}</p>
        </div>
      </div>
      <div class="price">￥{{item.currentPrice}}</div>
    </div>
  </div>
</template>

<script>
import { getCourseGroupCover } from '@/api/api'
export default {
  name: 'MatchingCourse',
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      list: []
    }
  },
  mounted() {
    if(this.detail.relationCourseGroupId) {
      this.getCourseGroupCover(this.detail.relationCourseGroupId)
    }
  },
  methods: {
    async getCourseGroupCover(val = '') {
      let params = {
        ids:val
      }
      let response = await getCourseGroupCover(params)
      if(+response.returnCode !== 10001) {
        this.$message.error(response.returnMsg)
        return false
      }
      this.list = response.data
    },
    itemClick(item) {
      this.$router.push({path: `/course/${item.id}`})
      this.$nextTick(() => {
        window.location.reload()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.m-course{
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  .item{
    width: 342px;
    height: 305px;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 0px rgba(115, 110, 110, 0.15);
    border-radius: 10px;
    margin: 15px;
    padding: 36px 19px 0;
    position: relative;
    cursor: pointer;
    .free-tag{
      position: absolute;
      top: 0;
      left: 20px;
      width: 32px;
    }
    .title{
      margin-bottom: 19px;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      height: 45px;
    }
    .tip{
      font-size: 14px;
      font-weight: 400;
      color: #5B6B73;
      line-height: 14px;
      margin-bottom: 15px;
    }
    .tip-space{
      margin-bottom: 32px;
    }
    .teacher-list{
      display: flex;
      height: 80px;
      .t-itam{
        margin: 0 16px 20px 0;
        img{
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          background: #eee;
          display: block;
          margin-bottom: 9px;
        }
        p{
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
          text-align: center;
        }
      }
    }
    .price{
      height: 46px;
      border-top: 1px solid #e8e8e8;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
      color: #FF3F47;
      line-height: 46px;
    }
  }
}
</style>