<template>
  <div class="card">
    <div class="title">
      <slot name="title-icon"></slot>
      <slot name="title-name"></slot>
    </div>
    
    
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: 'IntCard'
}
</script>

<style lang="less" scoped>
.card{
  background: #FFFFFF;
  border-radius: 10px;
  padding: 0 20px;
  color: #333333;
  font-size: 18px;
  .title{
    border-bottom: #E6E6E6 solid 1px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 71px;
  }
}
</style>