<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:46:59
 * @LastEditTime : 2022-05-20 15:07:09
 * @LastEditors  : BigBigger
-->
<template>
  <div class="c-in">
    <img v-for="(item, index) of introductionImgList" :key="index" :src="item" alt="课程介绍">
  </div>
</template>

<script>
export default {
  name: 'CourseIntroduct',
  data() {
    return {
      introductionImgList:[],
    }
  },
  props:{
    detail:{
      type:Object,
      default: null,
    }
  },
  mounted(){
    if(this.detail.introductionImg) {
      this.introductionImgList = this.detail.introductionImg.split(",")
    } else {
      this.introductionImgList = []
    }
  }
}
</script>

<style lang="less" scoped>
.c-in{
  padding: 20px 20px 20px 20px;
  img{
    margin: 0 auto;
    max-width: 100%;
    display: block;
  }
}
</style>