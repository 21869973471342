<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:46:59
 * @LastEditTime : 2022-07-20 16:55:24
 * @LastEditors  : BigBigger
-->
<template>
  <div class="detail-card" v-if="detail">
    <div class="card-content" v-if="detail.whetheHaveClassManager === 2">
      <div class="inc-img" v-if="detail.courseCover">
        <img :src="detail.courseCover" alt="">
      </div>
      <div class="inc-img" v-else>
        <img v-if="courseGroupType===1" src="@/assets/images/courseGroup/write.png" alt="">
        <img v-else src="@/assets/images/courseGroup/interview.png" alt="">
      </div>
      <div class="inc-content small-margin">
          <div class="title">{{detail.courseGroupName}}</div>
          <div class="tip">{{detail.subtitle}}</div>
          <div class="inline">
            <div class="tip-time"><span style="color:#999;">时间：</span>{{detail.classTime}}</div>
            <!-- <div class="tip-address">
              <div v-if="detail.classLocation">
                上课地址：
                <span>{{detail.classLocation}}</span>
              </div>
            </div> -->
            <!-- <div class="tip-type">
              上课形式：
              <span>{{detail.categoryName}}{{detail.classWay}}</span>
            </div> -->
          </div>
          <div class="tip-type" v-if="teachers">
              大课授课老师：<span>{{teachers}}</span>
            </div>
          <div class="class-box" v-if="detail.throughPay" >
            <div class="classList">
              <div class="infoList">
                <template v-if="detail.orderThroughPay === null">
                  <span>请选择付费模式（2选1）：</span>
                  <div class="class-item">
                      <p @click="throughPay = 1" class="name" :class="{active: throughPay === 1 }">{{THROUGH_PAY[1]}}</p>
                      <p @click="throughPay = 0" style="position: relative;" class="name" :class="{active: throughPay === 0 }">
                        {{THROUGH_PAY[0]}}
                        <img style="position: absolute;right: -10px;top:-10px;width: 33px;" src="@/assets/images/icon/youhui.png" alt="">
                      </p>
                  </div>
                </template>
                <template v-else>
                  <span>您选择的付费模式：</span>
                  <div class="class-item">
                      <p v-if="detail.orderThroughPay === 0" style="position: relative;" class="name" :class="{active: detail.orderThroughPay === 0 }">
                        {{THROUGH_PAY[0]}}
                        <img style="position: absolute;right: -10px;top:-10px;width: 33px;" src="@/assets/images/icon/youhui.png" alt="">
                      </p>
                      <p v-if="detail.orderThroughPay === 1" class="name" :class="{active: detail.orderThroughPay === 1 }">{{THROUGH_PAY[1]}}</p>
                  </div>
                </template>
              </div>
              <div class="class-extra">说明：{{ throughPay === 1 ? detail.msg1 : detail.msg2 }}</div>
            </div>
          </div>
          <div class="bottom" style="display:block">
            <div v-if='btnStateComputed.state === "立即报名"'>
              <span class="price-now">￥{{getPrice}}</span>
              <span class="price-old" v-if="detail.originalPrice">￥{{detail.originalPrice}}</span>
              <span class="price-now" v-if="detail.costDescription" style="font-size: 16px;">({{detail.costDescription}})</span>
            </div>
            <!-- 2:配套 -->
            <template v-if="detail.whetherReation === 2">
              <button class="buy-btn" @click="confirm(btnStateComputed.type)" v-if="btnStateComputed.canClick">{{btnStateComputed.state}}</button>
              <button class="buy-btn buy-btn-gray" v-if="!btnStateComputed.canClick">{{btnStateComputed.state}}</button>
            </template>
            <template v-if="detail.whetherReation !== 2">
              <button class="buy-btn buy-btn-gray" @click="ptclassTip">配套课程</button>
            </template>
            
          </div>
      </div>
    </div>

    <div class="card-content" v-else>
      <div class="inc-content merge-content">
        <div class="title">{{detail.courseGroupName}}</div>
        <div class="tip">{{detail.subtitle}}</div>
        <div class="tip-item">
          <div class="tip-type" v-if="teachers">
            大课授课老师：<span>{{teachers}}</span>
          </div>
          <!-- <div class="tip-type">
            上课形式：
            <span>{{detail.categoryName}}{{detail.classWay}}</span>
          </div> -->
          <div class="tip-address">
            <div v-if="detail.classLocation">
              上课地址：
              <span>{{detail.classLocation}}</span>
            </div>
          </div>
        </div>

        <div class="class-box">
          <div class="classList" v-for="(item1, index1) of detail.courseDetailClassManageWebVos" :key="index1">
            <div class="infoList">
              <span>{{item1.name}}</span>
              <div class="class-item">
                <template v-for="(item2, index2) of item1.courseDetailFlightManageWebVoList">
                  <div :key="index2" @click="selectItem(item2, item1.id)">
                    <p class="name" :class="{active: isSelected(item1.id, item2.id)}">{{item2.name}}：{{item2.startTime}}</p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="bottom">
          <div class="price-box" v-if='btnStateComputed.state === "立即报名"'>
            <template v-if="discount > 0">
              <span class="price-old" v-if="detail.originalPrice">原价￥{{detail.originalPrice}}，</span>
              <span class="discount" v-if="detail.originalPrice">优惠￥{{discount}}</span>
              <span class="price-now">优惠后价格￥{{detail.currentPrice}}</span>
            </template>
            <template v-else>
              <span class="price-now">价格￥{{detail.currentPrice}}</span>
            </template>
          </div>
          <!-- 2:配套 -->
          <template v-if="detail.whetherReation === 2">
            <button class="buy-btn" @click="confirm(btnStateComputed.type)" v-if="btnStateComputed.canClick">{{btnStateComputed.state}}</button>
            <button class="buy-btn buy-btn-gray" v-if="!btnStateComputed.canClick">{{btnStateComputed.state}}</button>
          </template>
          <template v-if="detail.whetherReation !== 2">
            <button class="buy-btn buy-btn-gray" @click="ptclassTip">配套课程</button>
          </template>
          
        </div>
      </div>
    </div>

    
  </div>
</template>

<script>
import { BTNSTATEFILTER, THROUGH_PAY } from '@/config/config'
import { getLessonGroupTips } from '@/api/api'
export default {
  name: "CourseGroupDetailCard",
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data(){
    return{
      THROUGH_PAY,
      courseGroupType:0,
      classList: [],
      selectedClass: {},
      discount:0,
      throughPay: 0,
    }
  },
  mounted() {
    this.getLessonGroupTips()
    
    //笔试面试占位图
    let categoryName = this.detail.categoryName
    if(categoryName.indexOf("面试")!== -1){
      this.courseGroupType = 0 
    }else{
      this.courseGroupType = 1 
    }
    if(this.detail.whetheHaveClassManager===1){
      this.selectedClass = this.initData(this.detail.courseDetailClassManageWebVos)
    
    }
    this.discount = this.detail.originalPrice - this.detail.currentPrice
  },
  computed: {
    btnStateComputed() {
      return BTNSTATEFILTER(this.detail)
      
    },
    isSelected() {
      return (id1, id2) => {
        return this.selectedClass[id1] && this.selectedClass[id1].id === id2
      }
    },
    teachers() {
      return (this.detail.teachers || []).map(i => i.teacherName).join('、')
    },
    getPrice() {
      return this.detail.throughPay === 1 && this.throughPay === 1 ? this.detail.showPrice : this.detail.currentPrice;
    }
  },
  methods: {
    async getLessonGroupTips() {
      let params = {
        courseGroupId: this.detail.id
      }
      let response = await getLessonGroupTips(params)
      if(+response.returnCode !== 10001) {
        return false
      }
      this.orderId = response.data.orderId
    },
    ptclassTip() {
      this.$message.warning('该课程无法购买')
    },
    confirm(type = 0) {
      if(type === 0) {
        if(this.$store.state.userInfo && this.$store.state.userInfo.token) {
          let arr = []
          for(let item in  this.selectedClass) {
            arr.push(this.selectedClass[item].id)
          }
          this.$store.dispatch('setOrderInfo',{
            courseGroupId: this.detail.id,
            courseGroupName: this.detail.courseGroupName,
            jobListId: this.detail.jobListId,
            loCourseInfo: this.detail,
            classInfo: arr,
            throughPay: this.throughPay
          })
          this.$nextTick(() => {
            this.$emit('confirm',this.detail.interviewFlag)
          })
        } else {
          this.$store.dispatch('toggleDialog',{isShow: true, type:0})
        }
      } else {
        this.$router.push({path: `/course/signup?step=3&orderId=${this.orderId}`})
      }
    },

    initData(courseDetailClassManageWebVos = []) {
      let selectObj = new Object();
      if(courseDetailClassManageWebVos){
        courseDetailClassManageWebVos.forEach(item => {
          if(item.courseDetailFlightManageWebVoList && item.courseDetailFlightManageWebVoList.length > 0) {
            selectObj[item.id] = item.courseDetailFlightManageWebVoList[0];
          } else {
            selectObj[item.id] = null;
          }
        })
        return selectObj;
      }
      
    },
    selectItem(item2, item1Id) {
      this.selectedClass[item1Id] = item2;
    },
  },
  
}
</script>

<style lang="less" scoped>
.detail-card{
  // height: 272px;
  background: #fff;
  padding: 29px 20px;
  border-radius: 10px;
  .card-content{
    width:100%;
    display: flex;
    justify-content: space-between;
    .inc-img{
      width: 483px;
      height: 271px;
      overflow: hidden;
      background: #eee;
      border-radius: 10px;
      img{
        width: 100%;
      }
    }
    .inc-content{
      width: 629px;
      .title{
        max-height: 62px;
        line-height: 31px;
        font-size: 22px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
        overflow: hidden;
      }
      .tip{
        font-size: 14px;
        font-weight: 400;
        color: #FF3F47;
        line-height: 14px;
        margin-bottom: 15px;
      }
      .tip-time{
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 15px;
      }
      .tip-type{
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        margin-bottom: 15px;
        span{
          color: #333333;
        }
      }
      .tip-address{
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        margin-bottom: 15px;
        span{
          color: #333333;
        }
      }
    }
    .merge-content{
      width:1200px;
      .tip-item{
        display: flex;
        .tip-type{
          margin-right: 38px;
        }
      }
      .class-box {
        padding:10px 0 20px 0;
        border-bottom:1px solid #E8E8E8;
      }
      .bottom{
        justify-content: flex-end;
        padding-top:30px;
        .price-box{
          color:#FF3F47;
          font-size: 16px;
          .price-old{
            color:#FF3F47;
            text-decoration:none;
          }
          .price-now{
            font-size: 22px;
            font-weight: bold;
            margin-left: 14px;
            margin-right: 25px;
          }
        } 
      }
    }
    .class-box{
        width: 100%;
        overflow: hidden;
        span{
          flex: 1 0 auto;
          min-width: 95px;
        }
        .infoList{
          width:100%;
          display: flex;
          overflow: hidden;
          line-height: 36px;
          padding:10px 0;
          font-size: 14px;
          .class-item{
            width:100%;
            .name{
              background: #F2F2F2;
              border-radius: 4px;
              // width:336px;
              height: 36px;
              line-height: 36px;
              color:#333333;
              font-size: 14px;
              float: left;
              padding:0 20px;
              margin:0px 10px;
              text-align: center;
              cursor: pointer;
            }
            .active{
              height: 34px;
              line-height: 34px;
              background: #FDEFEF;
              border: 1px solid #FF7773;
              border-radius: 4px;
              color: #FF3F47;
            }
          }
        }
        .class-extra {
          // color: #999;
          color: #FF3F47;
          font-size: 14px;
        }
      }
    .bottom{
      display: flex;
      align-items: flex-end;
    }
    .buy-btn{
      min-width: 148px;
      padding: 0 20px;
      height: 40px;
      line-height: 40px;
      background: linear-gradient(90deg, #F15F32, #F13232);
      border-radius: 6px;
      text-align: center;
      color: #FFFFFF;
      font-size: 18px;
      margin-top: 10px;
    }
    .buy-btn-gray{
      background: #dbdbdb;
    }
    .price-now{
      color: #FF3F47;
      font-size: 20px;
      margin-right: 9px;
    }
    .price-old{
      color: #999999;
      font-size: 16px;
      text-decoration:line-through;
    }
  }
  .inc-content.small-margin {
    .class-box {
      border-top: 1px solid #E8E8E8;
      margin-bottom: 10px;
      padding-top: 10px;
    }
    .tip, .tip-time, .tip-type, .tip-address {
      // margin-bottom: 10px;
    }
    .inline {
      overflow: hidden;
      div {
        float: left;
      }
      .tip-time {
        margin-right: 30px;
      }
    }
  }
  
}
</style>