<template>
  <div class="breadcrumb">
    <div class="bc-item" :class="{'hasarrow' : index !== (list.length - 1), 'cursor': item.url}" v-for="(item, index) of list" :key="index" @click="itemClick(item)">{{item.name}}</div>
    <!-- <div class="bc-item hasarrow">全部课程</div>
    <div class="bc-item ">【事业单位笔试-网课】2020年博学教育浙江省事业单位统考笔试网络课程</div> -->
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    itemClick(val) {
      if(val.url) {
        this.$router.push({path: val.url})
      }
    }
  }
}
</script>

<style lang="less" scoped>
.breadcrumb{
  color: #999999;
  font-size: 14px;
  height: 14px;
  line-height: 14px;
  display: flex;
  .hasarrow{
    &:after{
      font-family: element-icons!important;
      content: "\e6e0";
    }
  }
  .cursor{
    cursor: pointer;
  }
}
</style>